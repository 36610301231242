/* eslint-disable react/forbid-component-props */
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { BRAND_WOWCHER } from '../../../config/setup/setup';
import { trackEvent } from '../../../helpers/analytics';
import { centerItemInNav } from '../../../helpers/navigation';
import useClientSideEffect from '../../../helpers/useClientSideEffect';
import ThemeContext from '../../../providers/ThemeProvider';

/**
 * A generic link element for the navgation bar with option popover
 * Items are hidden based on the screen width via CSS media queries.
 */
const HeaderNavigationLink = ({
  children,
  dataTestId,
  delay = 200,
  disabled,
  handleSelection,
  href,
  isTravelSelected,
  popover,
  selected,
  shouldAutoScrollIntoView,
}) => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const [mouseInside, setMouseInside] = useState(false);
  const [open, setOpen] = useState(false);
  const element = useRef();
  const ssr = useSelector((state) => state.navigation.ssr);

  useClientSideEffect(() => {
    // wait for ssr === true -- we have to vait until the correct location is there else
    // the offset calculations will be wrong
    const isMatch = shouldAutoScrollIntoView && (selected || isTravelSelected);
    if (isMobileOnly && !ssr && isMatch) {
      centerItemInNav(element.current);
    }
  }, [ssr, router.asPath, selected, isTravelSelected]);

  const handleOnMouseEnter = () => {
    setMouseInside(true);
    if (handleSelection) handleSelection(dataTestId);
  };

  const handleOnMouseLeave = () => {
    setMouseInside(false);
    if (handleSelection) handleSelection('');
  };

  useEffect(() => {
    // slight delay to avoid accidental triggering
    const timer = setTimeout(() => {
      setOpen(mouseInside);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [mouseInside, delay]);

  const fireTrackEvent = () => {
    if (children[1] === 'VIP Hub') {
      trackEvent('viphub: nav click');
    }
  };

  return (
    <>
      <li
        className={classNames('navigation-item', {
          'navigation-item--disabled': disabled,
          'navigation-item--open': open,
          'navigation-item--selected': selected,
          'navigation-item--special': isTravelSelected,
        })}
        data-testid={dataTestId}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        ref={element}
      >
        <Link href={href} onClick={fireTrackEvent}>
          {href === '/gifts' ? (
            <div className="gift">
              <a
                className="gift__link"
                href={
                  process.env.NEXT_PUBLIC_BRAND === BRAND_WOWCHER
                    ? 'https://www.wowcher.co.uk/gifts'
                    : ''
                }
              >
                <FontAwesomeIcon className="navigation__icon" icon={faGift} />
                <span>{children}</span>
              </a>
            </div>
          ) : href === '/deal/dublin/gift-finder' ? (
            <div className="gift">
              <a className="gift__link" href={href}>
                <FontAwesomeIcon className="navigation__icon" icon={faGift} />
                <span>{children}</span>
              </a>
            </div>
          ) : (
            <a className="navigation-link">{children}</a>
          )}
        </Link>

        {open && popover && (
          <div
            className="popover-animation"
            onClick={handleOnMouseLeave}
            onKeyDown={handleOnMouseLeave}
            role="link"
            tabIndex="0"
          >
            {popover}
          </div>
        )}
      </li>
      <style jsx>{`
        .navigation-item {
          list-style: none;
          align-items: center;
          display: none;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
        }
        .navigation-item--selected {
          border-bottom: 2px solid ${theme.colors.navlinkactive};
        }
        .navigation-item--selected > .navigation-link {
          color: ${theme.colors.navlinkactive};
        }
        .navigation-item--disabled {
          pointer-events: none;
        }
        .navigation-item--special {
          background-color: #fff;
          border-bottom: 0;
        }
        .navigation-item--special .navigation-link {
          color: ${theme.colors.navlinkactive};
        }

        .navigation-link {
          height: 100%;
          color: ${theme.colors.navlink};
          cursor: pointer;
          padding: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: ${theme.text.navigation.transform};
          text-decoration: none;
          font-size: ${theme.text.navigation.size}px;
          white-space: nowrap;
        }
        .navigation-link:hover {
          color: ${theme.colors.navlinkactive};
        }
        .popover-animation {
          animation-fill-mode: backwards;
          animation: popover-in 0.2s;
        }

        @media (min-width: ${theme.breakpoints.xlUp}) {
          .navigation-item:nth-child(-n
              + ${theme.switches.navvisibilitycount.xl}) {
            display: flex;
          }
        }

        @media (min-width: ${theme.breakpoints.lgUp}) {
          .navigation-item:nth-child(-n
              + ${theme.switches.navvisibilitycount.lg}) {
            display: flex;
          }
        }

        @media (min-width: ${theme.breakpoints.mdUp}) {
          .navigation-item:nth-child(-n
              + ${theme.switches.navvisibilitycount.md}) {
            display: flex;
          }
        }

        @media (max-width: ${theme.breakpoints.mdDown}) {
          .navigation-item {
            display: flex;
          }
          .navigation-link {
            padding: 0 15px;
          }
        }
        .gift {
          display: flex;
        }
        .gift__link {
          align-items: center;
          background-color: ${theme.colors.headergiftfinderbackground};
          border-bottom: 2px solid transparent;
          color: ${theme.colors.headergiftfindertext};
          cursor: pointer;
          display: flex;
          font-size: ${theme.text.navigation.size}px;
          justify-content: center;
          padding: 10px 10px 5px 10px;
          text-decoration: none;
          text-transform: ${theme.text.navigation.transform};
          white-space: nowrap;
          height: 100%;
        }
      `}</style>
    </>
  );
};

HeaderNavigationLink.propTypes = {
  children: PropTypes.any,
  dataTestId: PropTypes.string,
  delay: PropTypes.number,
  disabled: PropTypes.bool,
  handleSelection: PropTypes.func,
  href: PropTypes.string,
  isTravelSelected: PropTypes.bool,
  popover: PropTypes.any,
  selected: PropTypes.bool,
  shouldAutoScrollIntoView: PropTypes.bool,
};

export default HeaderNavigationLink;
