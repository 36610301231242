import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { isLightPage } from './browser';
import { setDPCookie } from './cookieSetter';
import { getAppPlatform } from './device';

const headers = {
  accept: 'application/json',
  'Access-Control-Allow-Credentials': true,
  'app-platform': getAppPlatform(),
  brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
  'content-type': 'application/json',
  'country-code': (process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb').toUpperCase(),
  version: 'v1.1',
  webapp: true,
};

const httpCommonHeaders = (options = {}) => {
  const { customVersion = 'v1.1', isGPK = false } = options;
  const commonHeaders = { ...headers };
  let dynamicPrice = Cookies.load(COOKIES.dynamicPrice);
  if (!dynamicPrice && !isLightPage()) {
    setDPCookie();
    dynamicPrice = Cookies.load(COOKIES.dynamicPrice);
  }
  if (dynamicPrice) {
    commonHeaders[COOKIES.dynamicPrice] = dynamicPrice;
  }
  commonHeaders.version = customVersion;
  if (isGPK) {
    commonHeaders.gpkVersion = 'v1.1';
  }

  // TODO: do we need to read from adobe similar to angular

  return commonHeaders;
};

export default httpCommonHeaders;
