import dayjs from 'dayjs';

export const getBackendSocialCueMessage = (socialCuesData) => {
  if (!socialCuesData || Object.keys(socialCuesData).length === 0) return null;

  const hour = dayjs().hour();

  if (hour < 6 && socialCuesData.lastTwentyFourHours > 1) {
    return `${socialCuesData.lastTwentyFourHours} others bought this deal in the last 24 hours!`;
  } else if (hour >= 6 && hour < 12 && socialCuesData.lastSixHours > 1) {
    return `${socialCuesData.lastSixHours} others have already bought this deal today!`;
  } else if (hour >= 12 && hour < 18 && socialCuesData.lastTwelveHours > 1) {
    return `${socialCuesData.lastTwelveHours} others have already bought this deal today!`;
  } else if (socialCuesData.lastTwentyFourHours > 1) {
    return `${socialCuesData.lastTwentyFourHours} others bought this deal in the last 24 hours!`;
  } else {
    return null;
  }
};

/**
 * This is the helper that drives the red text on social cue messages for main deal
 * It is used for Main Deal social cue, Deal tile social cue and Calendar social cues.
 *
 * @typedef { deal: object, socialCuesData:object }
 * @property { deal } deal information from deal level
 * @property { socialCuesData } socialCuesData information from social cues API at deal level
 * @returns { string }
 */
export const getRedTextDealSocialCue = ({ deal, socialCuesData = null }) => {
  if (deal?.socialCueClaim) {
    return deal.socialCueClaim;
  }

  // default
  return '';
};

/**
 * This is the helper that drives the red text on social cue messages for products
 * It is used for Modal on deal page, Dropdown deals, Multiple Select deals and Checkout social cues.
 *
 * @typedef { product: object }
 * @property { product } product information from product level
 * @returns { string }
 */
export const getRedTextProductSocialCue = (product) => {
  if (product?.socialCueClaim) {
    return product.socialCueClaim;
  }

  // default
  return '';
};
