import { COMMON_COLORS, BASE_FONTS, CASE_TYPES } from '../constants/common';
import {
  LIGHT_BOX_MODAL,
  ERROR_TYPES,
  FLAGS,
  CYF_BANNER,
  CYF_BANNER_MOBILE,
} from '../constants/images';

const livingsocial = {
  bootstrap: {
    modalbackdropclassname: 'modal-backdrop-livingsocial',
  },
  boxshadow: `${COMMON_COLORS.dropshadow} 0 3px 6px`,
  breakpoints: {
    lgDown: '991.98px',
    lgUp: '992px',
    mdDown: '767.98px',
    mdUp: '768px',
    smDown: '575.98px',
    smUp: '576px',
    xlDown: '1199.98px',
    xlUp: '1200px',
    xs: '450px',
  },
  colors: {
    arrowbackground: '#3facd7',
    arrowcolor: COMMON_COLORS.white,
    background: '#ffffff',
    basketcount: COMMON_COLORS.basketcount,
    bgvalidcolor: COMMON_COLORS.bgvalidcolor,
    bidomicolor: COMMON_COLORS.white,
    bodytext: COMMON_COLORS.greycharcoal,
    borderbottom: '#3a3370',
    breadcrumb: '#000',
    breadcrumbhover: '#3facd7',
    breadcrumbpale: COMMON_COLORS.greyshade,
    buttonbordercolor: '#1e7192',
    buttonprimary: COMMON_COLORS.buttonprimary,
    buttonprimaryhover: COMMON_COLORS.buttonprimaryhover,
    buttonsecondary: COMMON_COLORS.buttonsecondary,
    buttonsecondaryhover: COMMON_COLORS.buttonsecondaryhover,
    buttontertiary: COMMON_COLORS.buttontertiary,
    buttontertiaryhover: COMMON_COLORS.buttontertiaryhover,
    buttontext: COMMON_COLORS.white,
    carouselAdds: COMMON_COLORS.peppermint,
    charcoal: '#333',
    checkoutlinkhover: COMMON_COLORS.linkhover,
    checkoutloginlink: '#3facd7',
    checkoutloginlinkactive: '#0f6c97',
    checkoutmodulebg: COMMON_COLORS.white,
    countdownnumbers: COMMON_COLORS.countdownnumbers,
    cyfbanner: '#544aa1',
    dealavailability: '#3facd7',
    dealbackgroundcolor: COMMON_COLORS.white,
    dealboughtcounttext: '#3a3370',
    dealline: COMMON_COLORS.darkgrey,
    dealmobiletitle: COMMON_COLORS.white,
    dealtext: COMMON_COLORS.greycharcoal,
    dealvideoicon: COMMON_COLORS.white,
    dealviewbutton: '#3a3370',
    didomibackgroundcolor: COMMON_COLORS.jet,
    dropshadow: COMMON_COLORS.dropshadow,
    emptydeal: COMMON_COLORS.greylighter,
    emptysearbackground: COMMON_COLORS.white,
    emptysearchmessage: '#666666',
    errorboxbg: COMMON_COLORS.invalidbg,
    errorboxborder: COMMON_COLORS.inputinvalidcolor,
    errorboxtext: COMMON_COLORS.invalidtext,
    filtermodal: COMMON_COLORS.white,
    filterslider: '#3facd7',
    filtervipswitch: '#5B9AD5',
    footerbackground: COMMON_COLORS.jet,
    footerlegaltext: COMMON_COLORS.white,
    footerlink: COMMON_COLORS.white,
    giftBannerBackground: '#6CBAE2',
    giftButton: '#6CBAE2',
    giftButtonArrow: '#296CB4',
    headerbackground: '#ffffff',
    headergiftfinderbackground: '#3facd7',
    headergiftfindertext: '#ffffff',
    headerlocationbackgroundactive: '#3facd7',
    headerlocationtext: '#666666',
    headerlocationtextactive: '#ffffff',
    headerprimary: '#3facd7',
    headingtext: '#000000',
    ihealthtext: COMMON_COLORS.greyshade,
    imagelink: '#3facd7',
    infoboxbg: COMMON_COLORS.infoboxbg,
    infoboxborder: COMMON_COLORS.infoboxborder,
    infoboxtext: COMMON_COLORS.infoboxtext,
    infoyellow: '#d6a700',
    infoyellowbg: '#f8df8680',
    inputdefaultborder: COMMON_COLORS.inputdefaultborder,
    inputfocuscolor: COMMON_COLORS.inputfocuscolor,
    inputiconcolor: COMMON_COLORS.inputiconcolor,
    inputinvalidcolor: COMMON_COLORS.inputinvalidcolor,
    inputlogincolor: COMMON_COLORS.inputlogincolor,
    inputvalidcolor: COMMON_COLORS.inputvalidcolor,
    lightboxbuttonbackground: '#3facd7',
    lightboxbuttonborderbottom: '3px solid #389ac1',
    lightboxbuttoncolor: COMMON_COLORS.white,
    lightboxcontainerborder: 'none',
    lightboxcontainerboxshadow: 'none',
    lightboxgrayborders: '#c7c7c7',
    lightboxgreenborders: '#6bba70',
    lightboxlegaltextcolor: '#65bcdf',
    lightboxlegaltextcolor768: '#333333',
    lightboxredborders: '#3facd7',
    lightboxsubmitbuttonbackground: 'linear-gradient(180deg,#544aa1 0,#544aa1)',
    lightboxsubmitbuttonborder: '3px solid #3a3370',
    linealgradient1: '#544aa1',
    linealgradient2: '#544aa1',
    linkhover: COMMON_COLORS.linkhover,
    minimenu: '#2792bc',
    mobilemenubg: COMMON_COLORS.white,
    navbackground: '#f0f0f0',
    navborder: '#d2d2d2',
    navlink: '#666666',
    navlinkactive: '#3facd7',
    navmorebackground: '#ffffff',
    navmoreheader: '#999999',
    notificationblue: '#2c90e9',
    notificationbluedark: '#4a90e2',
    notificationbluelight: '#eaf4fd',
    pandpcolor: '#ADD8E6',
    paymentbuttonbg: COMMON_COLORS.greyxlighter,
    primary: '#3facd7',
    primarydark: '#2792bc',
    primaryonwhite: '#3facd7',
    primarypale: '#b8deef',
    primarypromotion: '#3facd7',
    referfriendbanner: '#f7a0c6',
    searchbg: '#dff1f8',
    searchbgfocused: '#ffffff',
    searchborder: '#3facd7',
    searchborderfocused: '#000000',
    searchbuttonbg: 'transparent',
    searchbuttonbgfocused: '#000000',
    searchbuttonfocused: '#ffffff',
    searchplaceholder: '#aaaaaa',
    searchtext: '#000000',
    secondary: '#00EBD9',
    socialcuecolor: {
      alert: COMMON_COLORS.alert,
      assure: COMMON_COLORS.assure,
      assurelighter: COMMON_COLORS.assurelighter,
      brandcolor: '#3facd7',
      primary: '#eb008c',
      textonbrandcolor: '#ffffff',
      textonprimary: '#ffffff',
    },
    stepactivecolor: '#3facd7',
    stepcompletedcolor: 'rgb(1, 185, 1)',
    stepinactivecolor: '#3facd760',
    subnavbackground: '#fff',
    subscribebutton: '#3facd7',
    successboxbg: COMMON_COLORS.bgvalidcolor,
    successboxborder: COMMON_COLORS.inputvalidcolor,
    successboxtext: COMMON_COLORS.validtext,
    successgreen: COMMON_COLORS.green,
    sucessgreenborder: COMMON_COLORS.greendark,
    textonprimary: '#ffffff',
    textonsecondary: '#ffffff',
    tilebordercolor: '#3facd7',
    toggleOffColor: '#9f9f9f',
    toggleOnColor: '#4ed064',
    white: '#fff',
  },
  commoncolors: COMMON_COLORS,
  fonts: {
    base: BASE_FONTS,
    theme: 'Nunito',
  },
  images: {
    cyf: {
      banner: CYF_BANNER.brands.livingSocial,
      bannermobile: CYF_BANNER_MOBILE.brands.livingSocial,
    },
    dealsplat: 'https://resources.wowcher.co.uk/images/ls/LS-Blobs-yellow.png',
    dealvipsplat: 'https://resources.wowcher.co.uk/images/splatVIP.svg',
    error404: ERROR_TYPES.brands.livingSocial[404],
    flags: {
      headerflagie: FLAGS.ireland,
      headerflaguk: FLAGS.unitedKingdom,
    },
    headerlogo:
      'https://resources.wowcher.co.uk/images/ls/livingsocial-header-logo.svg',
    headerlogodefheight: '42',
    headerlogodefwidth: '112',
    headerlogolgwidth: '149px',
    headerlogomdwidth: '120px',
    headerLogoReversed:
      'https://resources.wowcher.co.uk/images/ls/livingsocial-header-logo-white.svg',
    lightbox: {
      popeye: LIGHT_BOX_MODAL.brands.livingSocial.background.popeye,
      subscribe: LIGHT_BOX_MODAL.brands.livingSocial.background.subscribe,
    },
    vipheaderlogo:
      'https://resources.wowcher.co.uk/assets/img/brand/living-social/VIP/LS-VIP-Logo-Colour300x113.svg',
  },
  label: 'Living-social',
  styles: {
    breadcrumbtextshadow: 'none',
  },

  switches: {
    navvisibilitycount: {
      lg: 7,
      md: 5,
      xl: 10,
    },
    showCareersImageLink: false,
    showFlags: true,
  },
  text: {
    cardbutton: {
      fontsize: `3vw`,
      lineheight: `3vw`,
    },
    cta: {
      button: CASE_TYPES.lowerCase,
    },
    error404: CASE_TYPES.lowerCase,
    error404placeholder: 'inherit',
    lightbox: CASE_TYPES.lowerCase,
    logintitle: {
      transform: CASE_TYPES.lowerCase,
    },
    navigation: {
      size: 16,
      transform: CASE_TYPES.lowerCase,
    },
    search: CASE_TYPES.lowerCase,
    transform: CASE_TYPES.lowerCase,
  },
};

export default livingsocial;
