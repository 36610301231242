import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { getAppPlatform } from './device';

const headers = {
  accept: 'application/json',
  'Access-Control-Allow-Credentials': true,
  'app-platform': getAppPlatform(),
  brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
  'content-type': 'application/json',
  'country-code': (process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb').toUpperCase(),
  version: 'v1.1',
  webapp: true,
};

const httpUserCommonHeaders = () => {
  const commonHeaders = headers;
  const dynamicPrice = Cookies.load(COOKIES.dynamicPrice);
  if (dynamicPrice) {
    commonHeaders[COOKIES.dynamicPrice] = dynamicPrice;
  }
  if (commonHeaders['all-locations']) {
    delete commonHeaders['all-locations'];
  }

  // TODO: do we need to read from adobe similar to angular
  return commonHeaders;
};

export default httpUserCommonHeaders;
