import { COMMON_COLORS } from '../constants/common';
import livingsocial from './livingsocial';
import wowcher from './wowcher';

const christmas = {
  livingsocial: {
    ...livingsocial,
    images: {
      ...livingsocial.images,
      bgcolorwash:
        'https://resources.wowcher.co.uk/images/ls/livingsocial-xmas-colourwash.jpg',
      headerlogo:
        'https://resources.wowcher.co.uk/images/ls/livingsocial-xmas-logo--black.svg',
    },
  },
  wowcher: {
    ...wowcher,
    colors: {
      ...wowcher.colors,
      primarypromotion: COMMON_COLORS.black,
      trustpilotbackground: 'transparent',
    },
    images: {
      ...wowcher.images,
      bgcolorwash:
        'https://cfimg.wowcher.co.uk/cdn-cgi/image/height=991,width=2000,quality=70,format=auto/https://static.wowcher.co.uk/binaries/Wow-Snow-Gift-Colourwash2.jpg',
      headerlogo:
        'https://resources.wowcher.co.uk/assets/img/brand/wowcher/logo/Wow-Christmas-logo.png',
    },
    padding: {
      trustpilotbottom_mdup: '0',
    },
    styles: {
      ...wowcher.styles,
    },
  },
};

export default christmas;
