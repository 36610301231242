import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { LINK_CHECKOUT_BUTTON } from '../config/links/links';
import { BRAND_WOWCHER, GIFTING_OPTIONS } from '../config/setup/setup';
import { createBasket, fetchBasket } from '../redux/actions/basket';
import { replaceBasket } from '../services/basket';
import { trackUnbxdAddToCart } from './analytics';
import errorTrackingService from './errorTrackingService';
import { redirectToURL } from './url';
import { getIsEligibleForVip } from './vipSubscribeHelper';

const giftingBuilder = (deliveryType, isAGift) => {
  if (
    !isAGift ||
    process.env.NEXT_PUBLIC_BRAND !== BRAND_WOWCHER ||
    deliveryType === 'none'
  )
    return null;

  return {
    gift: isAGift ? isAGift : null,
    giftPackProductId: null,
    giftProductId: null,
    type: isAGift ? GIFTING_OPTIONS.E_GIFT : null,
  };
};

// Basket object builders
export const productBuilder = (
  deal,
  locations,
  position = 0,
  isAGift = false,
  quantity = -1,
  payDeposit = false,
) => {
  const product = deal.products[Number.parseInt(position, 10)];
  const isPPDeal = deal?.pricePerPerson;

  const getProductQuantity = () => {
    let productQuantity = quantity >= 0 ? quantity : product?.quantity || 1;

    if (isPPDeal && productQuantity === 1) {
      productQuantity = 2;
    }

    return productQuantity;
  };

  trackUnbxdAddToCart(deal?.id, getProductQuantity(), product?.id);

  return {
    adId: product?.adId || null,
    bookingCalendarOnRedemption: deal?.bookingCalendarOnRedemption || false,
    checkInDate: product?.checkInDate || null,
    checkOutDate: product?.checkOutDate || null,
    dealId: deal.id,
    dealProductId: product?.id,
    dealType: deal?.productDisplay?.type || 'default',
    depositDisplayPrice: product?.depositProduct?.price || null,
    depositProduct: product?.depositProduct || null,
    depositProductId: product?.depositProduct?.id || null,
    discount: product?.discount,
    discountPercentage: product?.discountPercentage,
    discountValue: product?.discount,
    displayDiscount: product?.displayDiscount,
    flights: product?.flights || null,
    gift: isAGift,
    giftClaim: deal?.giftClaim || false,
    gifting: giftingBuilder(deal.deliveryType || false, isAGift),
    hasDeposit: product?.hasDeposit,
    hideProductDiscount: !product?.displayDiscount,
    id: product?.id,
    numberOfNights: product?.numberOfNights,
    originalPrice: product?.originalPrice,
    payDeposit: product?.payDeposit || product?.isDepositOption || payDeposit,
    postagePrice: product?.postagePrice,
    price: product?.price,
    purchaseCap: product?.purchaseCap,
    purchaseGclid: Cookies.load(COOKIES.gclid) || null,
    purchaseLocation: locations?.location?.shortName,
    purchaseUrl: Cookies.load(COOKIES.purchaseUrl),
    quantity: getProductQuantity(),
    sessionPurchaseSource: Cookies.load(COOKIES.sessionSource) || null,
    title: product?.title,
  };
};

// then we need to merge a guest basket when the user has logged in
export const basketCreator = (
  deal,
  locations,
  position = 0,
  isAGift = false,
  payDeposit = false,
) => {
  const selectedPosition = position;
  if (Array.isArray(selectedPosition)) {
    // MultiSelect Option
    const newProducts = [];
    selectedPosition.map((item, index) => {
      if (!item) return false;
      newProducts.push(
        productBuilder(
          deal,
          locations,
          index,
          isAGift,
          Number.parseInt(item, 10),
          payDeposit,
        ),
      );

      return true;
    });

    return newProducts;
  }

  return [
    productBuilder(deal, locations, selectedPosition, isAGift, -1, payDeposit),
  ];
};

export const mergeProducts = (previousProducts, newProducts) => {
  // TODO: We need to merge the products and increase the value of the wrapper items
  // const itemsDealIds = previousProducts.map((item) => item.dealId);
  const itemsDealIds = previousProducts.map((item) => item.id);

  // We remove the duplicated products
  const newProductsFiltered = newProducts.filter((item) => {
    //  if (itemsDealIds.includes(item.dealId)) {
    if (itemsDealIds.includes(item.id)) {
      const indexNew = previousProducts.findIndex(
        // (i) => i.dealId === item.dealId
        (index) => index.id === item.id,
      );
      if (indexNew >= 0) {
        if (!item.quantity) {
          // we remove the item if has not quantity, this is for multi select options
          previousProducts.splice(indexNew, 1);

          return false;
        }
        // eslint-disable-next-line fp/no-mutating-assign
        Object.assign(previousProducts[indexNew], {
          bookingCalendarOnRedemption: item.bookingCalendarOnRedemption || null,
          checkInDate: item.checkInDate || null,
          // include (possibly) updated checin/out dates
          checkOutDate: item.checkOutDate || null,
          giftClaim: item.giftClaim || false,
          payDeposit: item.payDeposit || false,
          quantity: item.quantity || 1,
        });
      }

      return false;
    }
    if (!item.quantity) return false;

    return true;
  });

  if (!newProductsFiltered || !newProductsFiltered.length)
    return previousProducts;

  return [...previousProducts, ...newProductsFiltered];
};

const getWrapItem = (giftWrap, product) => {
  return {
    dealId: giftWrap.dealId,
    gift: false,
    gifting: {
      gift: false,
      giftPackProductId: null,
      giftProductId: null,
      type: 'none',
    },
    giftPack: false,
    giftWrap: true,
    id: giftWrap.productId,
    payDeposit: product.payDeposit || product.isDepositOption,
    quantity: 1,
  };
};

export const addWrappItem = (giftWrap, items, product) => {
  if (!giftWrap || !giftWrap.dealId || !giftWrap.productId) return items;

  let itemFound = false;
  const newItems = items.map((item) => {
    if (item.dealId !== giftWrap.dealId) return item;
    item.quantity += Number.parseInt(
      product.quantity ? product.quantity : 1,
      10,
    );
    itemFound = true;

    return item;
  });
  if (!itemFound) {
    newItems.push(getWrapItem(giftWrap, product));
  }

  return newItems;
};

export const getGiftPackQuantity = (products, giftPackId) => {
  return products.find((product) => product.id === giftPackId);
};

export const getTotalProductsQuantity = (products) => {
  if (products.length > 0)
    return products.reduce((total, item) => total + item.quantity, 0);
  else return 0;
};

export const createBasketAndMoveToCheckout = async (
  deal,
  locations,
  position = 0,
  isAGift = false,
  payDeposit = false,
  isAuthenticated,
  userVipStatus,
  router,
) => {
  const basketToken = Cookies.load(COOKIES.basketToken);
  const customerToken = Cookies.load(COOKIES.customerToken);
  const isExistingBasket = basketToken?.length > 10;

  try {
    const currentBasket = await fetchBasket(
      basketToken,
      customerToken,
      isAuthenticated,
    );
    const products = basketCreator(
      deal,
      locations,
      position,
      isAGift,
      payDeposit,
    );
    const mergedProducts = currentBasket?.products
      ? mergeProducts(currentBasket.products, products)
      : products;

    const basketTokenAux = isExistingBasket
      ? await replaceBasket(
          basketToken,
          mergedProducts,
          isAuthenticated,
          getIsEligibleForVip(isAuthenticated, userVipStatus),
        )
      : await createBasket(
          mergedProducts,
          getIsEligibleForVip(isAuthenticated, userVipStatus),
        );

    if (!basketTokenAux && !basketToken) {
      throw new Error('No Basket Token');
    }

    redirectToURL(
      `${LINK_CHECKOUT_BUTTON}/${basketToken || basketTokenAux}`,
      router,
    );
  } catch (error) {
    errorTrackingService.logError({
      error: error.message || 'Basket Creation Failed',
      message: 'Basket token or operation failed.',
    });
  }
};
