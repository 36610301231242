import { useRouter } from 'next/router';
import React, { useState } from 'react';
import cookie from 'react-cookies';
import { APP_BANNER_ICON } from '../config/constants/images';
import { PAGE_TYPES } from '../config/constants/page-types';
import COOKIES from '../config/cookies/cookies';
import { APP_BANNER_TITLE, OPEN_IN_APP } from '../config/text/text';
import { trackEvent } from '../helpers/analytics';
import commonCookiesOptions from '../helpers/commonCookiesOptions';
import { isWowcherBrand } from '../helpers/pages';
import { getPlatform, getDeepLink } from '../helpers/platform';
import { parseWowcherPath } from '../helpers/url';

export const OpenInApp = () => {
  const router = useRouter();
  const isClosedCookieSaved = Boolean(cookie.load(COOKIES.smartbannerClosed));
  const [isClosed, setIsClosed] = useState(isClosedCookieSaved);

  const { pageType } = parseWowcherPath(router.asPath);
  const isButtonVisible =
    pageType === PAGE_TYPES.category || pageType === PAGE_TYPES.homepage;

  const currentPlatform = getPlatform();

  if (currentPlatform === '' || isClosed) return null;

  const { deepLink } = getDeepLink(currentPlatform);

  const onOpenAppClick = (event) => {
    event.preventDefault();
    trackEvent('smartbanner_open_app', { currentPlatform });
    window.location.href = deepLink;
  };

  const setCookie = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 2);
    cookie.save(COOKIES.smartbannerClosed, 'true', {
      ...commonCookiesOptions,
      expires,
    });
  };

  const onCloseClick = () => {
    setCookie();
    // Shift the map view widget down when open in app button
    const mapViewWidgetButton = document.querySelector('.map-view-btn-group');
    if (mapViewWidgetButton) {
      mapViewWidgetButton.classList.remove('open-app-btn-visible');
    }
    trackEvent('smartbanner_close', { currentPlatform });
    setIsClosed(true);
  };

  return (
    isButtonVisible && (
      <>
        <div className="app-button-wrapper">
          <img
            alt={APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE]}
            src={APP_BANNER_ICON[process.env.NEXT_PUBLIC_SITE]}
          />
          <button className="text" onClick={onOpenAppClick} type="button">
            {OPEN_IN_APP}
          </button>
          <button className="closex" onClick={onCloseClick} type="button">
            X
          </button>
        </div>
        <style jsx>{`
          .app-button-wrapper {
            position: fixed;
            z-index: 1000;
            bottom: 20px;
            width: 220px;
            left: 50%;
            margin-left: -110px;

            line-height: 1;
            border-radius: 30px;
            padding: 2px 10px;
            height: auto;
            background-color: #fff;

            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            height: 50px;
          }

          .app-button-wrapper img {
            width: 50px;
            height: 50px;
            padding: ${isWowcherBrand() ? '0' : '5px'};
          }

          .app-button-wrapper button {
            -webkit-appearance: none;
            border: none;
            background-color: transparent;
          }

          .app-button-wrapper .closex {
            display: inline-block;
            position: relative;
            padding: 10px;
            height: auto;
            font-size: 1.5em;
            color: #333;
          }

          .app-button-wrapper .text {
            text-align: center;
            line-height: 1.6;
            font-size: 16px;
            color: #1474fc;
            white-space: nowrap;
          }
        `}</style>
      </>
    )
  );
};
