/* eslint-disable filenames/match-exported */
/* eslint-disable id-match */
/* eslint-disable promise/param-names */
/* eslint-disable fp/no-mutating-assign */
import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'react-cookies';
import { v4 as uuidv4 } from 'uuid';
import COOKIES from '../../../config/cookies/cookies';
import {
  LINKS_GLOBAL_SPLUNK,
  LINK_NOTFOUNDHIT,
  CLOUDFLARE_TRACE,
  POST_LOGS,
} from '../../../config/links/links';
import {
  SPLUNK_TOKEN,
  SPLUNK_ACTIVATED,
  SPLUNK_SENT_WINDOW_INFORMATION,
  AXIOS_RESPONSE_TIMEOUT,
  AXIOS_CONNECT_TIMEOUT,
} from '../../../config/setup/setup';
import httpCommonHeaders from '../../../helpers/httpCommonHeaders';

const dateFormat = 'DD/MMMM/YYYY HH:mm:ss';

// Creates a unique guid for the requests and stores it as a cookie
export const getGuid = () => {
  if (!Cookies.load(COOKIES.splunkGuid)) {
    const generatedGuid = uuidv4();
    Cookies.save(COOKIES.splunkGuid, generatedGuid, 365);
  }

  return Cookies.load(COOKIES.splunkGuid);
};

// Use editConfig only in client request, not in SSR
export const editConfig = (data) => {
  if (typeof window === 'undefined' || !window) return data;

  if (window.navigator && window.navigator.userAgent) {
    Object.assign(data, { 'User-Agent': window.navigator.userAgent });
  }
  if (
    window.platform &&
    window.platform.os &&
    window.platform.os.family &&
    window.platform.os.version
  ) {
    Object.assign(data, {
      'Platform-family': window.platform.os.family,
      'Platform-version': window.platform.os.version,
    });
  }

  return data;
};

const fetchWithTimer = (url, options, timeout = AXIOS_CONNECT_TIMEOUT) => {
  return Promise.race([
    axios(url, options),
    new Promise((_, reject) =>
      setTimeout(async () => {
        return reject(new Error(`Connection timeout: ${timeout}`));
      }, timeout),
    ),
  ]);
};

export const SplunkHttp = (data) => {
  return false;
};

// Default response timeout
axios.defaults.timeout = AXIOS_RESPONSE_TIMEOUT || 2_500;
axios.defaults.withCredentials = true;

// All these functions needs to be called only on the FE
const getIPfromText = (text) => {
  const ipIndex = text.indexOf('ip=');
  const tsIndex = text.indexOf('ts=');
  if (!ipIndex || !tsIndex) return '';

  return text.slice(ipIndex + 3, tsIndex).trim();
};

const requestIPfromCloudflare = async () => {
  try {
    const response = await fetch(CLOUDFLARE_TRACE);
    const dataReceived = await response.text();

    return await getIPfromText(dataReceived);
  } catch (error) {
    console.log('Unable to request IP from Cloudflare', error);

    return '';
  }
};

const sendErrorInfoToSplunk = (defaultHeaders, body) => {
  defaultHeaders.Authorization = `Splunk ${
    SPLUNK_TOKEN[process.env.NEXT_PUBLIC_ENVIRONMENT]
  }`;

  try {
    return fetch(LINK_NOTFOUNDHIT, {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: defaultHeaders,
      method: 'POST',
    });
  } catch (error) {
    console.error('Unable to send error info to Splunk', error);
  }
};

const getDataToSend = (IP, path) => {
  const now = dayjs();

  return {
    additionalData: IP,
    brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
    dateTime: now.format(dateFormat),
    pageNotFound: path,
    userAgent: navigator ? navigator.userAgent : '',
  };
};

export const sendErrorDataToSplunk = async (route) => {
  const IP = await requestIPfromCloudflare();
  const data = getDataToSend(IP, route.asPath);

  return sendErrorInfoToSplunk(httpCommonHeaders(), data);
};

// return an axios wrapper with connection timeout, if we dont want to use it, return axios instead
export default fetchWithTimer;

export const logToSplunk = async (extraData, description = null) => {
  const IP = await requestIPfromCloudflare();
  const now = dayjs();

  const data = {
    brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
    bt: Cookies.load(COOKIES.basketToken) || null,
    client: '',
    ct: Cookies.load(COOKIES.customerToken) || null,
    dateTime: now.format(dateFormat),
    description: description || 'Log to splunk',
    ip: IP,
    server: '',
  };

  Object.assign(data, extraData);
  try {
    return fetch(POST_LOGS, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        ...httpCommonHeaders(),
        Authorization: `Splunk ${
          SPLUNK_TOKEN[process.env.NEXT_PUBLIC_ENVIRONMENT]
        }`,
      },
      method: 'POST',
    });
  } catch (error) {
    console.error('Unable to log to Splunk', error);

    return null;
  }
};

const getBotDataToSend = (IP, path) => {
  const now = dayjs();

  return {
    additionalData: IP,
    brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
    dateTime: now.format(dateFormat),
    pageNotFound: path,
    reason: 'This is a bot',
    userAgent: navigator ? navigator.userAgent : '',
  };
};

export const sendBotAlert = async (route) => {
  const IP = await requestIPfromCloudflare();
  const data = getBotDataToSend(IP, route.asPath);

  try {
    return fetch(POST_LOGS, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        ...httpCommonHeaders(),
        Authorization: `Splunk ${
          SPLUNK_TOKEN[process.env.NEXT_PUBLIC_ENVIRONMENT]
        }`,
      },
      method: 'POST',
    });
  } catch (error) {
    console.error('Unable to send error info to Splunk', error);

    return null;
  }
};
