import {
  APP_BANNER_DEEPLINK_APPLE,
  APP_BANNER_DEEPLINK_ANDROID,
} from '../config/links/links';
import { APP_BANNER_APPLE, APP_BANNER_GOOGLE } from '../config/text/text';

export const getPlatform = () => {
  let platform = '';

  if (typeof window !== 'undefined') {
    const nua = window.navigator.userAgent;
    let result = '';
    if (/iphone|ipod/i.test(nua)) {
      result = APP_BANNER_APPLE;
    }
    if (/android/i.test(nua)) {
      result = APP_BANNER_GOOGLE;
    }
    platform = result;
  }

  return platform;
};

export const getDeepLink = (currentPlatform) => {
  let link = '';

  if (currentPlatform === APP_BANNER_APPLE) {
    link = APP_BANNER_DEEPLINK_APPLE[process.env.NEXT_PUBLIC_SITE];
  }

  if (currentPlatform === APP_BANNER_GOOGLE) {
    link = APP_BANNER_DEEPLINK_ANDROID[process.env.NEXT_PUBLIC_SITE];
  }

  return {
    deepLink: link,
  };
};
