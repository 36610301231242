import Cookies from 'react-cookies';
import { DEALS } from '../../config/constants/action-types';
import {
  KEY_BUY_AGAIN_DEALS,
  KEY_RECENTLY_VIEWED_DEALS,
  KEY_RECOMMENDED_DEALS,
} from '../../config/constants/key-payload-types';
import {
  CHECK_OUT_LAYOUT,
  ACCOUNT_LAYOUT,
  DELETE_LAYOUT,
  GIFT_LAYOUT,
  HEADER_FOOTER_LAYOUT,
  DEALS_LAYOUT,
} from '../../config/constants/layout-types';
import COOKIES from '../../config/cookies/cookies';
import {
  DESKTOP_PAGE_SIZE,
  LINK_NOT_LOGGED_USER,
} from '../../config/links/links';
import {
  URLLIVEDEAL,
  URLGETRECOMMENDATION,
  CUSTOMER_DEFAULT_TOKEN,
  SITE_WOWCHERIE,
  URLGETBUYAGAIN,
} from '../../config/setup/setup';
import {
  setLoginSourcePageType,
  setPostQueryLoginUrl,
} from '../../helpers/cookieSetter';
import { getStoredDealsFromLocalStorage } from '../../helpers/recentlyViewed';
import { isSSR } from '../../helpers/ssr';
import { redirectToURL } from '../../helpers/url';
import { isUserAuthenticated } from '../../helpers/user';
import { getIsEligibleForVip } from '../../helpers/vipSubscribeHelper';
import { validateAuthenticatedCustomerBasket } from '../../services/basket';
import { getBasket } from './basket';
import { getDealsData, getDeals, getDealPreview } from './deals';
import { getScrollerConfiguration } from './scroller';
import { getServerSideDeal } from './ssr';
import { getProfile } from './user';
import { getWishlist } from './wishlist';

export const getClientSideDeals = ({
  emailDealLocation,
  isEmailDeal,
  isPreview = false,
  pageNumber = null,
  pageSize = DESKTOP_PAGE_SIZE,
  path,
  query = {},
  isLightweight = false,
  secondaryDealsCount,
  sideDealsLocal,
}) => {
  const localStorageDeals = getStoredDealsFromLocalStorage();
  const userId = Cookies.load(COOKIES.customerToken) || CUSTOMER_DEFAULT_TOKEN;

  return async (dispatch) => {
    const ssr = isSSR();
    if (ssr) {
      return Promise.resolve([]);
    }

    if (isLightweight) {
      const request = [
        dispatch(
          getDeals({
            emailDealLocation,
            isCategoryPage: true,
            isEmailDeal,
            isPreview,
            pageSize,
            path,
            secondaryDealsCount,
            sideDealsLocal,
            slug: query.slug,
            ssr,
          }),
        ),
      ];

      return Promise.all(request);
    }
    const requests = [
      // Gets called again on the client for dynamic pricing for main deals
      // TODO : ask backend team if they could just requests for just dynamic pricing to help with performance
      dispatch(
        getDeals({
          emailDealLocation,
          isCategoryPage: true,
          isEmailDeal,
          isPreview,
          pageNumber,
          pageSize,
          path,
          secondaryDealsCount,
          sideDealsLocal,
          slug: query.slug,
          ssr,
        }),
      ),
      // Recommended Deals based on userId carousel
      process.env.NEXT_PUBLIC_SITE !== SITE_WOWCHERIE &&
        dispatch(
          getDealsData(
            KEY_RECOMMENDED_DEALS,
            URLGETRECOMMENDATION(userId),
            DEALS.SET_RECOMMENDED_DEALS,
          ),
        ),

      // Get Deals for client side based on whats been viewed stored on local storage
      // Recently viewed carousel
      localStorageDeals.length > 0
        ? dispatch(
            getDealsData(
              KEY_RECENTLY_VIEWED_DEALS,
              `${URLLIVEDEAL}${localStorageDeals}`,
              DEALS.SET_RECENTLY_VIEWED_DEALS,
              true,
            ),
          )
        : null,
      // get scroller configuration
      dispatch(getScrollerConfiguration(ssr)),
    ];

    return Promise.all(requests);
  };
};

// This very similar to getServerSideDeal but to keep the consistent framework
// we duplicate this as this could change in the near future
export const getClientSideDeal = ({
  query = {},
  dealId = null,
  secondaryDealsCount,
  byPassSSR = null,
  path,
  pageSize = DESKTOP_PAGE_SIZE,
  emailDealLocation,
  sideDealsLocal,
  isEmailDeal,
}) => {
  return async (dispatch) => {
    dispatch(
      getServerSideDeal({
        byPassSSR,
        dealId,
        emailDealLocation,
        isEmailDeal,
        pageSize,
        path,
        query,
        secondaryDealsCount,
        sideDealsLocal,
      }),
    );
  };
};

// This is used to be called to dispatch layout essentials for different types of layout
export const getClientSideLayoutEssentials = (user, layout, pageType) => {
  if (!user) {
    throw new Error('getClientSideLayoutEssentials: Requires user');
  }

  if (isSSR()) {
    return Promise.resolve([]);
  }

  return async (dispatch) => {
    const isAuthenticated = isUserAuthenticated(user);

    // merge baskets when a user is in a authenticated state
    // we do not want this to be called on the checkout as it has a different validate basket flow
    if (layout !== CHECK_OUT_LAYOUT && isAuthenticated) {
      const bt = Cookies.load(COOKIES.basketToken);
      await validateAuthenticatedCustomerBasket(
        bt,
        getIsEligibleForVip(isAuthenticated, user?.userProfile?.userVipStatus),
      );
    }

    const requests = [];

    switch (layout) {
      case CHECK_OUT_LAYOUT:
      case GIFT_LAYOUT:
        if (!isAuthenticated) {
          requests.push(dispatch(getProfile()));
        }
        break;
      case ACCOUNT_LAYOUT:
      case DELETE_LAYOUT:
        // redirect user to login page as they are not authenticated or not have a user profile
        if (!isAuthenticated && !Cookies.load(COOKIES.userLoggedIn)) {
          // save the url so we can come back after login
          if (window) {
            const url = window.location.pathname + window.location.search;
            setPostQueryLoginUrl(url);
          }
          // save the pagetype for message on login
          setLoginSourcePageType(pageType);
          redirectToURL(LINK_NOT_LOGGED_USER);
        }

        // This may not be required as its gona have to login and update test
        // This needs to be tested
        requests.push(dispatch(getProfile()));
        break;
      case HEADER_FOOTER_LAYOUT:
      case DEALS_LAYOUT:
        if (!isAuthenticated) {
          requests.push(dispatch(getProfile()));
        }
        requests.push(dispatch(getBasket(user?.isAuthenticated)));
        requests.push(dispatch(getWishlist()));
        break;
    }

    return Promise.all(requests);
  };
};

export const getClientSideRecommendedDeals = () => {
  const userId = Cookies.load(COOKIES.customerToken) || CUSTOMER_DEFAULT_TOKEN;

  return async (dispatch) => {
    dispatch(
      getDealsData(
        KEY_RECOMMENDED_DEALS,
        URLGETRECOMMENDATION(userId),
        DEALS.SET_RECOMMENDED_DEALS,
      ),
    );
  };
};

export const getClientSideBuyAgainDeals = () => {
  return async (dispatch) => {
    dispatch(
      getDealsData(
        KEY_BUY_AGAIN_DEALS,
        URLGETBUYAGAIN,
        DEALS.SET_BUY_AGAIN_DEALS,
      ),
    );
  };
};

export const getClientSideDealPreview = (uid) => {
  return async (dispatch) => {
    dispatch(getDealPreview(uid));
  };
};
