import { COMMON_COLORS } from '../constants/common';
import livingsocial from './livingsocial';
import wowcher from './wowcher';

const blackfriday = {
  livingsocial: {
    ...livingsocial,
    colors: {
      ...livingsocial.colors,
      breadcrumb: COMMON_COLORS.white,
      breadcrumbpale: COMMON_COLORS.white,
    },
    images: {
      ...livingsocial.images,
      bgcolorwash:
        'https://cfimg.wowcher.co.uk/cdn-cgi/image/height=990,width=2000,quality=50,format=auto/https://static.wowcher.co.uk/binaries/LS-black-friday-2024-colourwash.jpg',
      headerlogo:
        'https://resources.wowcher.co.uk/assets/img/brand/living-social/logo/LS-BF-logo.svg',
    },
  },
  wowcher: {
    ...wowcher,
    colors: {
      ...wowcher.colors,
      breadcrumb: COMMON_COLORS.white,
      breadcrumbpale: COMMON_COLORS.white,
      trustpilotbackground: COMMON_COLORS.white,
    },
    images: {
      ...wowcher.images,
      bgcolorwash:
        'https://cfimg.wowcher.co.uk/cdn-cgi/image/height=990,width=2000,quality=70,format=auto/https://static.wowcher.co.uk/binaries/BF24-Colourwash4.jpg',
      headerlogo:
        'https://resources.wowcher.co.uk/assets/img/brand/wowcher/logo/Wow-BF-logo.svg',
    },
    padding: {
      trustpilotbottom_mdup: '18px',
    },
  },
};

export default blackfriday;
