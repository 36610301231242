import find from 'lodash/find';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import {
  ALL_CAT_PAGE,
  DEFAULT_SEO_TEXT,
  DEFAULT_SEO_TEXT_CATEGORY,
  DEFAULT_SEO_TEXT_SUBCATEGORY,
} from '../config/text/text';
import { isWowcherBrand } from './pages';
import { parseWowcherPath } from './url';

export const getCategoryName = (navigation, shortName) => {
  for (const entry of navigation) {
    if (entry.shortName === shortName) {
      return entry.linkText || shortName;
    }

    for (const subEntry of entry?.subNavigations || []) {
      if (subEntry.shortName === shortName) {
        return subEntry.linkText || shortName;
      }
    }
  }

  return shortName;
};

const getDefaultNavItem = ({
  facetedNavigation,
  location,
  navigation,
  path,
}) => {
  const {
    pathArray,
    details: { isTravelHomepage, isTravel },
  } = parseWowcherPath(path);
  const pathLocation = pathArray[1];

  let url = `/${location}/${facetedNavigation.category}`;
  if (pathLocation === 'shop') {
    url = facetedNavigation?.parentNavUrl
      ? facetedNavigation?.parentNavUrl
      : `/shop/${facetedNavigation.category}`;
  } else if (isTravelHomepage) {
    // escapes or travel depending on brand
    url = `/${pathLocation}`;
  } else if (isTravel) {
    // escapes or travel depending on brand
    url = `/${pathLocation}/${facetedNavigation.category}`;
  }

  const categoryName =
    getCategoryName(
      navigation,
      isTravelHomepage ? pathLocation : facetedNavigation.category,
    ) || '';

  const allDealsText = ALL_CAT_PAGE.replace('##word##', categoryName);

  return {
    categoryName,
    count: facetedNavigation.count ? facetedNavigation.count : 0,
    isDefault: true,
    linkText: allDealsText,
    url,
  };
};

export const parseFacetedNavigation = ({
  facetedNavigation,
  location,
  navigation,
  path,
}) => {
  if (!facetedNavigation || !facetedNavigation.navigations) return {};
  if (!find(facetedNavigation.navigations, { isDefault: true })) {
    const defaultNavItem = getDefaultNavItem({
      facetedNavigation,
      location,
      navigation,
      path,
    });
    facetedNavigation.navigations.unshift(defaultNavItem);
    facetedNavigation.defaultItem = defaultNavItem;
  }
  forEach(facetedNavigation.navigations, (item, index) => {
    facetedNavigation.navigations[index].url = replace(
      item.url,
      'national-deal',
      'shop',
    );

    if (!isWowcherBrand()) {
      const pathArray = item.url.split('/');
      if (pathArray[1] === 'travel') {
        pathArray[1] = 'escapes';
      }
      facetedNavigation.navigations[index].url = pathArray.join('/');
    }

    const prependUrlWithDeals =
      !facetedNavigation.navigations[index].url.includes('/deals') &&
      !facetedNavigation.navigations[index].url.includes('/near-me') &&
      !facetedNavigation.navigations[index].url.includes('/find');

    if (prependUrlWithDeals) {
      facetedNavigation.navigations[index].url =
        '/deals' + facetedNavigation.navigations[index].url;
    }

    const isActive = item.url === path.split('?')[0];
    if (isActive) {
      facetedNavigation.defaultItem = item;
    }
    facetedNavigation.navigations[index].isActive = isActive;
  });

  return facetedNavigation;
};

export const getSubCatsWithPicture = (facetedNavigation, path) => {
  if (!facetedNavigation || !facetedNavigation.navigations) return [];
  const filtered = facetedNavigation.navigations.filter(
    (item) => item.imageUrl && item.imageUrl !== 'null',
  );
  forEach(filtered, (item) => {
    const isActive = item.url === path;
    if (isActive) {
      item.isActive = true;
    }
  });

  return filtered;
};

export const getDefaultSeoText = (facetedNavigation) => {
  if (!facetedNavigation || Object.keys(facetedNavigation).length === 0) {
    return DEFAULT_SEO_TEXT;
  }
  if (
    facetedNavigation.defaultItem &&
    facetedNavigation.defaultItem.categoryName
  ) {
    return DEFAULT_SEO_TEXT_CATEGORY.replace(
      /##category##/gi,
      facetedNavigation.defaultItem.categoryName,
    );
  }
  if (facetedNavigation.defaultItem && facetedNavigation.defaultItem.linkText) {
    return DEFAULT_SEO_TEXT_SUBCATEGORY.replace(
      /##subcategory##/gi,
      facetedNavigation.defaultItem.linkText,
    );
  }

  return DEFAULT_SEO_TEXT;
};
