export const centerItemInNav = (element) => {
  if (element) {
    const nav = document.querySelector('nav');
    if (nav) {
      const center = Math.ceil(nav.offsetWidth / 2);
      const { offsetLeft, offsetWidth } = element;
      const left = offsetLeft - (center - Math.ceil(offsetWidth / 2));
      nav.scrollTo({ behavior: 'smooth', left });
    }
  }
};

export const getURLs = ({ currentPage, lastPage, url }) => {
  const cleanUrl = url.split('/').pop().split('?')[0];
  const previousPage =
    currentPage === 2 ? 0 : currentPage === 0 ? null : currentPage - 1;
  const nextPage =
    currentPage === lastPage ? null : currentPage === 0 ? 2 : currentPage + 1;
  const previousUrl =
    currentPage === 2
      ? cleanUrl
      : currentPage === 0
      ? null
      : `${cleanUrl}?page=${currentPage - 1}`;
  const nextUrl =
    currentPage === lastPage
      ? null
      : currentPage === 0
      ? `${cleanUrl}?page=2`
      : `${cleanUrl}?page=${currentPage + 1}`;

  return { cleanUrl, nextPage, nextUrl, previousPage, previousUrl };
};
